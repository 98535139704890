
import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { Icon } from "./../components/helpers.js"
import { FiMail, FiHome, FiBox, FiMenu, FiShoppingCart } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { SnipcartContext } from 'gatsby-plugin-snipcart-advanced/context';

var slugify = require('slugify')

export default (props) => {

    const Menu = styled.ul`
        float:right;
        padding:0px;
        margin:0px;
        line-height:76px;
        font-size:1.15em;
        font-family: 'Arial', sans-serif;
        a, button {
            text-decoration:none;
            outline:none;
            border:0px;
            padding:0.6em;
            background-color:#ffffff;        
            font-weight:bold;
            cursor:pointer;
            :hover {
                color:#999;
            }
        }

        & > li {
            margin:0px 0px 0px 1em;
            padding:0px;
            display:inline-block;
            position:relative;

            :hover > ul {
                display:block;  
            }

            &.submenuActive > button {
                background-color:#333;
                color:#fff;
                border-radius:6px 6px 0px 0px;
            }

            &.submenuHidden > ul {
                display:none;
            }
        }

        @media (max-width: 1024px) 
        {    
            float:none;  
            margin:0px;
            padding:0px;  

            &.menuHidden {
                display:none;
            }
            
            & > li {
                display:block;
                margin:0px;
                padding-left:calc(54px + 3vw);
            }

            & > li:not(:first-child)
            {
                margin-top:0.5em;
                padding-top:0.5em;
                border-top:1px solid #ccc;
            }

            & > li:last-child
            {
                padding-bottom:0.8em;
            }

            a, button {
                padding-left:0px;
                padding-right:0px;
            }

            & > li.submenuActive > button {
                background-color:#fff;
                color:#000;
                border-radius:0px;
                :hover {
                    color:#999;
                }
            }

        }
    `;

    const Submenu = styled.ul`
        font-size:0.7em;
        list-style:none;
        position:absolute;
        background-color:#333;
        top:0px;
        left:0px;
        white-space: nowrap;
        margin:44px 0px 0px 0px;
        padding:1em 1em 1em 1em;  
        border-radius:0px 6px 6px 6px;
        z-index:4;
        a {
            display:block;       
            color:#fff; 
            background-color:#333;  
            :hover {
                color:#ddd;
            }
        }
        li:not(:first-child) a
        {
            margin-top:0.5em;
            padding-top:0.5em;
            border-top:1px solid #555;
        }

        @media (max-width: 1024px) 
        {
            position:relative;
            border-top:1px solid #ccc;
            margin:0px;
            padding:0px;
            margin-bottom:calc(-0.5em - 2px);
            border-radius:0px;
            font-size:0.8em;
            background-color:#f5f5f5;
            margin-left:calc(-54px - 3vw);
            
            a {            
                padding-left:calc(54px + 3vw);
                color:#000;   
                background-color:#f5f5f5;               
                :hover 
                {
                    color:#999;
                }
            }

            li:not(:first-child) a
            {
                margin-top:0em;
                padding-top:0.5em;
                border-top:1px solid #ccc;
            }
        }
    `;

    const Hamburger = styled.a`

        display:none;
        line-height:76px;
        font-size:25px;
        float:right;
        cursor:pointer;
        
        svg 
        {
            width: 1em;
            height: 1em;
            vertical-align:-0.25em;
        }

        @media (max-width: 1024px) 
        {    
            display:inline;
            margin-right:3vw;
        }

    `;

    const { state } = useContext(SnipcartContext);
    const { cartQuantity } = state;

    var domsubmenu;
    const projectlist = props.projects;
    
    for (var i = projectlist.length - 1; i >= 0; i--) {
        domsubmenu = <>{domsubmenu}<li><Link to={"/" + slugify(projectlist[i].name, { lower: true }) + '/'} onClick={() => props.setMenu(false)}>{projectlist[i].name}</Link></li></>
    }
    domsubmenu = <Submenu>{domsubmenu}</Submenu>;

    var cart;

    if (cartQuantity > 0)
        cart = <li><button className="snipcart-checkout"><Icon><FiShoppingCart /></Icon> <span>{cartQuantity}</span></button></li>    

    return(<><Hamburger onClick={(e) => {if (props.visible){props.setSubmenu(false);};props.setMenu(!props.visible);e.stopPropagation()}}><FiMenu /></Hamburger>
    <Menu className={props.visible ? "menuActive" : "menuHidden"}>
            <li><Link to="/" onClick={() => props.setMenu(false)}><Icon><FiHome /></Icon> Home</Link></li>
            <li className={props.subvisible ? "submenuActive" : "submenuHidden"}><button onClick={(e) => {props.setSubmenu(!props.subvisible);e.stopPropagation()}}><Icon><FiBox /></Icon> Projects</button>
            {domsubmenu}    
        </li>        
            <li><Link to="/shop/bmw-bluetooth-adapter/" onClick={() => props.setMenu(false)}><Icon><AiOutlineShop /></Icon> Shop</Link></li>
            <li><Link to="/contact/" onClick={() => props.setMenu(false)}><Icon><FiMail /></Icon> Contact</Link></li>
            {cart}
    </Menu></>)

}