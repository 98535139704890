import styled from 'styled-components'
import React from "react"
import { FiExternalLink} from "react-icons/fi";
import { FaFilePdf } from "react-icons/fa";

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import cpp from 'react-syntax-highlighter/dist/esm/languages/prism/cpp';
import arduino from 'react-syntax-highlighter/dist/esm/languages/prism/arduino';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import yaml from 'react-syntax-highlighter/dist/esm/languages/prism/yaml';

SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('cpp', cpp);
SyntaxHighlighter.registerLanguage('arduino', arduino);
SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('yaml', yaml);

export const FirstP = styled.p`    
    font-weight: bold;
    color:#000;    

    &:first-letter {
        font-size: 3.5em;
        float: left;
        margin-top: .29em;
        margin-right: .12em;
        font-weight: normal;
    }
`;

export const Warning = styled.p`    
    background-color:#FDF4DD;
    padding:0.5em;
    font-family: 'Arial', sans-serif;
    font-size:0.9em !important;
    color:#C89109;
    border:1px solid #C89109;
    border-radius:4px;

    a {
        color:#C89109 !important;
    }
`;

export const Icon = styled.span`    
    vertical-align: -2px;
`;

export const Caption = styled.p`    
    font-family: 'Arial', sans-serif;
    color:#aaa;
    text-align: center;
    margin-top:6px;
    font-size:0.9em !important;
`;

export const Small = styled.p`    
    font-family: 'Arial', sans-serif;
    color:#aaa;
    line-height:1.5em !important;
    font-size:0.9em !important;
`;

export const Table = styled.table`
    text-align:left;
    width:100%;
    line-height:2.4em;
    border-collapse: collapse;        
    tr:not(:last-child) {
        border-bottom:1px solid #ddd;
    }

    td, th {
        vertical-align:top;
    }

    td:not(:last-child), th:not(:last-child) {
        padding-right:12px;
    }
`;

export const Pinout = styled.table`
    margin:32px auto;
    border:2px solid #333;

    td
    {
        border:1px solid #333;
        font-family: 'Arial', sans-serif;
        font-size:0.9em;
        font-weight:700;
        padding:0.7em;
        width:5em;
        text-align: center;
    }
`;

export const Extern = (props) => (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children} <Icon><FiExternalLink /></Icon></a>);

export const Hackaday = () => (<img style={{height: '1em'}} src={require('./../img/hackaday.png')} alt="Hackaday" />)

export const Emoji = (props) => <span aria-hidden="true" role="img">{props.e}</span>;

export const Pre = styled.span`    
    background-color: #eee;
    color: #000;
    font-family: Consolas, monospace;
    padding: 3px;
    border:1px solid #CCC;
    border-radius:4px;
`;

const StyledSyntax = styled(SyntaxHighlighter)`
    font-size:0.82em;
    max-width:54vw;
    @media (max-width: 1024px) 
    {
        max-width:calc(90vw - 2em);
    }
`;

export const Python = ({ className, children }) => <StyledSyntax className={className} language="python" style={atomDark}>{children}</StyledSyntax>
export const Cpp = ({ className, children }) => <StyledSyntax className={className} language="cpp" style={atomDark}>{children}</StyledSyntax>
export const Arduino = ({ className, children }) => <StyledSyntax className={className} language="arduino" style={atomDark}>{children}</StyledSyntax>
export const YAML = ({ className, children }) => <StyledSyntax className={className} language="yaml" style={atomDark}>{children}</StyledSyntax>
export const Javascript = ({ className, children }) => <StyledSyntax className={className} language="jsx" style={atomDark}>{children}</StyledSyntax>

export const Project = styled.div`
    &:not(:last-of-type) {
        margin-bottom:30px;
        padding-bottom:14px;
        border-bottom: 1px solid #ddd;
    }

    .projectTag {
        background-color: #3b95df;
        border-radius: 4px;
        color:#fff;
        font-size:0.9em;
        padding:5px 7px;  
        margin-left:2px;  
        vertical-align:1px;
    }

    .sale svg {
        margin-right:2px;
    }

    .projectTag a {
        color:#fff !important;
        text-decoration: none;

        :hover {
            text-decoration:underline;
        }
    }


    .projectTag.finished {
        background-color:rgb(158, 197, 14);
    }

    .projectTag.research {
        background-color:rgb(140, 140, 140);
    }

    .projectTag.ongoing {
        background-color:rgb(228, 154, 19);
    }

    p {
        margin-top:-5px;
    }

    h3 {
        margin-top:18px;
        margin-bottom: -14px;
        line-height: 1.6em;
    }
`;

const DatasheetDiv = styled.div`
    box-shadow:0px 0px 3px 0px rgba(0,0,0,0.2);
    background-color: #fdfdfd;
    padding:0.8em;
    border-radius:4px;
    font-family: 'Arial', sans-serif;
    color:#f40f02 !important;
    display:flex;

    & > span {
        font-size:2.4em;
        margin-right:0.3em;
        margin-bottom:-0.3em;
    }

    h4 {
        margin:0em 0em 0.2em 0em;
    }

    a {
        font-size:0.9em;
    }
`;

export const Datasheet = (props) => (<DatasheetDiv>
    <Icon><FaFilePdf /></Icon>
    <div><h4>{props.title}</h4><Extern href={props.href}>Datasheet</Extern></div></DatasheetDiv>);
